'use client';

import { useEffect } from 'react';
import { useAppState } from '../providers/state-provider';

export default function SetSermonModalOpen({
  isOpen = true,
}: {
  isOpen?: boolean;
}) {
  const { dispatch } = useAppState();

  useEffect(() => {
    dispatch({ type: 'sermonModalOpen', payload: isOpen });
  }, [isOpen]);

  return null;
}
